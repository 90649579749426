footer {
  padding: 8rem 0;
  background: #1d1f27;
  color: #fff;
  text-align: center;

  a {
    color: #44a8e1;
  }

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
    }
  }

  .twitter-footer {
    margin: 8rem auto 0;
    max-width: 400px;
  }
}