.contact {
  padding: 8rem 0;
  background: #f2f2f2;
  text-align: center;

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
    }

    a {
      background: linear-gradient(to left, #36d1dc, #5b86e5);
      color: #fff;
      padding: 1rem 2rem;
      display: inline-block;
      margin-top: 1rem;
      font-size: 1.6rem;
      text-decoration: none;
      border-radius: 5px;
    }
  }
}