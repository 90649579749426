.youtube {
  padding: 8rem 0;
  background: #1d1f27;
  color: #fff;
  text-align: center;

  a {
    color: #44a8e1;
  }

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
      margin-bottom: 6rem;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}