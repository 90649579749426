.about {
  padding: 8rem 0;
  text-align: center;

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
    }

    .about-profile {
      border-radius: 50%;
      margin-bottom: 4rem;
      width: 150px;
      height: 150px;
    }

    .body-copy {
      max-width: 500px;
      margin: 0 auto;
    }
  }
}