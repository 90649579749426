.brands {
  padding: 8rem 0;
  background: #f2f2f2;
  text-align: center;

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
    }
  }

  .brands-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 20px);
    grid-gap: 15px;
    padding: 4rem 0 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 120px;
        padding: 10px 0;
      }
    }
  }
}


@media screen and (min-width: 800px) {
  .brands {
    .brands-group {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(1, 5vw);
      padding: 4rem 0;
    }
  }
}