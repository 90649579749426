@font-face {
  font-family: 'proxima nova';
  src: url('../fonts/proxima_nova_regular.eot');
  src: url('../fonts/proxima_nova_regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_regular.woff') format('woff'),
    url('../fonts/proxima_nova_regular.woff2') format('woff2'),
    url('../fonts/proxima_nova_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'gotham pro';
  src: url('../fonts/GothamPro-Bold.eot');
  src: url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GothamPro-Bold.woff') format('woff'),
    url('../fonts/GothamPro-Bold.woff2') format('woff2'),
    url('../fonts/GothamPro-Bold.ttf') format('truetype');
}