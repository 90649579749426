@import './base';

@import './typography.scss';

// Layouts
@import './layout/_nav.scss';
@import './layout/_footer.scss';


// Routes
@import './routes/homepage/index.scss';

* {
  margin: 0;
  padding: 0;
  font-family: 'proxima nova';
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'gotham pro';
}