.hero {
  background: linear-gradient(to left, #36d1dc, #5b86e5);
  padding-bottom: 8rem;

  .container {
    display: flex;
    height: 100%;

    h1 {
      font-size: 14vw;
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 1.8rem;
    }

    .social-links {
      margin-top: 2rem;
      display: flex;
      flex-flow: wrap;
      justify-content: center;

      a {
        color: #fff;
        text-decoration: none;
        font-size: 1.6rem;
        margin: 0 0.7rem;
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.lottie {
  max-width: 400px;
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 640px) {
  .hero .container{
    h1 {
      font-size: 6rem;
    }
  }
}