.works {
  padding: 8rem 0;
  text-align: center;

  .container {
    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.6rem;
    }

    .works-group {
      padding: 4rem 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(5, 192px);
      grid-gap: 15px;
    }

    .works-thumbnail {
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      color: #fff;
      position: relative;
      top: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      // transition: all .3s ease-in-out;
      overflow: hidden;
      width: 310px;
      max-width: 310px;
      margin: 0 auto;
      left: 0;
      right: 0;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.6;
        transition: opacity .3s ease-in-out;
      }

      div {
        text-align: center;
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 2.3rem;
          text-shadow: 0px 2px 24px #000;
        }

        p {
          margin-bottom: 10px;
          text-shadow: 0px 2px 24px #000;
        }
      }

    }

    .works-thumbnail:hover {
      img {
        opacity: 0.9;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .works {
    .container {
      .works-group {
        padding: 4rem 0;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 192px);
        grid-gap: 15px;
      }

      .works-thumbnail {
        width: 100%;
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1060px) {
  .works {
    .container {
      .works-group {
        padding: 4rem 0;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 192px);
        grid-gap: 15px;
      }

      .works-thumbnail {
        width: 310px;
        max-width: 310px;

        img {
          width: 100%;
        }
      }
    }
  }
}