* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 960px;
}